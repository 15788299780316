import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src='./MiaGuruLogo.png' alt='MiaGuru Logo' height={100}/>
        <div>Welcome to MiaGuru!</div>
        <div>This website is comming soon!</div>
      </header>
    </div>
  );
}

export default App;
